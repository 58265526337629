@use '../abstracts/' as *;

.Heading {
  padding-bottom: 28px;

  &--title-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
  }

  &--title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  &--right-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    align-self: center;
  }

  &--subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
