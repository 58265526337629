@use '../abstracts/' as *;

.AddMemberModal {
  &--users {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }

  &--button-wrapper {
    justify-items: end;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 10px;
    >button {
      height: 40px;
      border-radius: 25px;
    }
    >button:hover {
      box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
    }
  }

  &--user {
    font-size: 13px;
    background-color: rgba(217, 217, 217, 0.4);
    border: 1px solid #0000001A;
    border-radius: 4px;
    padding: 12px;
    display: grid;
    grid-template-columns: 10% 45%;
    justify-items: normal;
    grid-column-gap: 10px;
    cursor: pointer;

    &-avatar {
      background-image: url('../../images/no-avatar.jpg');
      width: 38px;
      height: 38px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 20px;
      border: 1px solid #a3a3a3;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    }

    &-wrapper{
      display: grid;
      justify-items: normal;
      &-title {
        font-weight: 700;
      }
      &-subtitle {
        color: rgba(63, 67, 75, 0.52);
      }
    }
  }

  &--email {
    position: relative;
    background-color: rgba(217, 217, 217, 0.4);
    border: 1px solid #0000001A;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50px auto;
  
    &-icon {
      background-image: url('../../images/email.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 25px;
      width: 50px;
    }
  
    &-wrapper {
      display: grid;
      grid-template-rows: auto auto;
      padding: 8px;
    }
  
    &-text {
      font-size: 13px;
    }
  
    &-subtext {
      font-size: 11px;
    }
  }  
}

.selected {
  border: 1px solid $blue;
}