@use '../abstracts/' as *;

.SideDataGridItem {
  &--one {
    display: grid;
    grid-template-columns: 20px 1fr;
    border-bottom: 1px solid rgba(213, 213, 213, 0.6);
  }

  &--expander-closed {
    padding: 12px 12px;
    background-image: url('../../images/expander-grey.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
  &--expander-open {
    padding: 12px 12px;
    background-image: url('../../images/expanded-grey.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
  &--inner-grid {
    grid-gap: 24px;
    display: grid;
    padding: 12px;
    &-item {
      line-height: 12px;
    }
    &-icon-item {
      line-height: 12px;
    }
  }

  &--inner-grid-icon {
    height: 12px;
    transition: .5s;
    &:hover {
      transform: scale(3);
    }
  }
  &--expanded {
    display: grid;
    grid-gap: 24px;
    border-top: 1px solid rgba(213, 213, 213, 0.6);
    border-bottom: 1px solid rgba(213, 213, 213, 0.6);

    width: 100%;
    padding: 12px;
    line-height: 12px;
    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 0.38) 0%,
      rgba(217, 217, 217, 0) 80%
    );
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
  }
  &--expanded-indent {
    border-top: 1px solid rgba(213, 213, 213, 0.6);
  }
}
