@use '../abstracts/' as *;

.AddOrganizationModal {
  &--steps > div > div > .Heading > .Heading--title-wrapper {
    display: none;
  }

  &--stepTransitionRight-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  &--stepTransitionRight-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }

  &--stepTransitionRight-exit {
    transform: translateX(0);
    opacity: 1;
  }

  &--stepTransitionRight-exit-active {
    display: none;
  }

  &--stepTransitionLeft-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  &--stepTransitionLeft-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }

  &--stepTransitionLeft-exit {
    transform: translateX(0);
    opacity: 1;
  }

  &--stepTransitionLeft-exit-active {
    display: none;
    transform: translateX(0%);
    opacity: 0;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }
}
