@use '../abstracts/' as *;

.InvoicesDropdown {
  position: relative;
  cursor: pointer;
  padding: 12px;
  display: flex;
  user-select: none;
  &--title {
    line-height: 24px;
    font-weight: 700;
  }
  &--menu {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $light-grey;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.13);
    position: absolute;
    left: 20px;
    top: 58px;
    z-index: 100;
    min-width: 310px;
  }
  &--menu-title {
    padding: 12px 17px;
    border-bottom: 1px solid $light-grey;
    cursor: default;
    font-weight: bold;
  }
  &--menu-list {
    max-height: 190px;
    overflow: scroll;
    scrollbar-width: none;
  }
  &--menu-list-item {
    padding: 6px 17px;
    border-bottom: 1px solid $light-grey;
    cursor: pointer;
    background-image: url("../../images/chevron.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 20px);
    min-height: 31.5px;
  }
  &--menu-list-item:hover {
    background-color: #f8f9f9;
  }
  &--transition-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  &--transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
  &--transition-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
}