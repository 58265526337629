@use '../abstracts/' as *;

.ShareFile {
  @extend .AddMemberModal !optional;
  &--users {
    display: grid;
    margin-top: 12px;
    gap: 10px;
  }
  &--user {
    font-size: 13px;
    //background-color: rgba(217, 217, 217, 0.4);
    border: 1px solid #0000001A;
    border-radius: 4px;
    padding: 12px;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    justify-items: normal;
    grid-column-gap: 10px;
    cursor: pointer;

    &-delete {
      background-image: url('../../images/close.svg');
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      top: 40%;
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(217, 217, 217, 0.4);
    }
  }
}