@use '../abstracts/' as *;
@import 'button';

.IconButton {
  @extend .Button;
  display: grid;
  align-items: center;
  grid-template-columns: 8% 92%;
  padding: 0 15px;
  user-select: none;
  justify-items: center;
  &--text{
    font-size: 16px;
    font-weight: 700;
    padding-right: 20px;
    &--white{
      color: white;
    }
    &--black{
      color: black;
    }
  }
  &--black{
    background-color: black;
    border: 1px solid darken($black, 20%);
  }
  &--white{
    background: $white;
    border: 1px solid darken($white, 20%);
  }
}