@use '../../abstracts/' as *;

.Usage {
  &--scroll {
    max-height: 500px;
    overflow: scroll;
  }
  &--next-invoice {
    line-height: 48px;
    padding-right: 12px;
    b {
      font-weight: 800;
    }
  }
  &--rate-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: linear-gradient(180deg, #ffffff 44.27%, #f3f3f3 100%);
    height: 100px;
    align-content: center;
    justify-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &--rate-item {
    width: fit-content;
    display: block;
  }
  &--rate-title {
    text-align: left;
    color: #a6a6a6;
  }
  &--rate-value {
    text-align: left;
    color: #3f434b;
    font-size: 30px;
  }
  &--rate-item {
    text-align: center;
  }
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--title {
    margin: 0 12px;
    height: 40px;
    line-height: 40px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  &--grid {
    margin: 0 12px;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 12px;
    margin-bottom: 24px;
  }
  &--nav {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px;
    padding: 12px;
    border-left: 3px solid #4091e7;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
    cursor: pointer;
  }
  &--info {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px;
    margin-bottom: 12px;
  }
  &--info-title {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &--info-delete {
    background-image: url('../../../images/close.svg');
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
  }
  &--info-title-text {
    padding: 12px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    cursor: pointer;
    user-select: none;
  }
  &--info-title-subtext {
    padding: 12px;
    line-height: 18px;
    font-weight: 500;
  }
  &--info-title-btn {
    height: 24px;
    background: #edeef0;
    border: 1px solid #edeef0;
    border-radius: 3px;
    color: #434649;
    font-style: normal;
    font-weight: 800;
    font-size: 11px;
    line-height: 15px;
    padding: 0 24px;
    cursor: pointer;
    margin: 12px;
  }
  &--info-header {
    text-align: left;
    display: grid;
    grid-template-columns: 100px 1fr 150px 200px 60px;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 25%,
      rgba(217, 217, 217, 0.45) 100%
    );

    div {
      margin: 12px;
      line-height: 12px;
      color: #858585;
    }
  }
  &--info-row {
    display: grid;
    grid-template-columns: 100px 1fr 150px 200px 60px;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    div {
      margin: 12px;
      line-height: 12px;
    }
  }
}
