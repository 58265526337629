@use '../../abstracts/' as *;

.Encryption {
  &--KeyPair {
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 135px;
  }
}
