@use '../abstracts/' as *;

.ButtonInput {
  margin-bottom: 12px;
  position: relative;
  width: 100%;
  color: #D9D9D9;
  display: grid;
  max-width: 176px;
  &--input {
    border-radius: 8px;
    border: 0;
    min-height: 138px;
    cursor: pointer;
  }
  &--label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 6px;
  }
  &--description {
    padding: 18px 6px;
    color: #aaaaaa;
    font-size: 12px;
  }
}

.active {
  border: solid 2px #2d73ff;
}

.Button:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}
