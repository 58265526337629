@use '../../abstracts/' as *;

.People {
  &--grid-organization{
    display: grid;
    @include media('>985px') {
      justify-items: normal;
      grid-template-columns: 10% 90%;
      grid-column-gap: 10px;
    }
    grid-row-gap: 10px;
    justify-items: center;
    grid-template-columns: 1fr;
  }
  &--avatar {
    background-image: url('../../../images/no-avatar.jpg');
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    border: 1px solid #a3a3a3;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
  }
  &--name{
    font-weight: 700;
    align-self: center;
  }
  &--left {
    padding: 12px;
    display: grid;
    @include media('>985px') {
      grid-template-columns: 10% 45% 40%;
      justify-items: normal;
      grid-column-gap: 10px;
    }
    grid-row-gap: 10px;
    justify-items: center;
    grid-template-columns: 1fr;
    &-wrapper{
      display: grid;
      justify-items: center;
      @include media('>985px') {
        justify-items: normal;
      }
      &-title {
        font-weight: 700;
      }
      &-subtitle {
        color: rgba(63, 67, 75, 0.52);
      }
    }
  }
}
