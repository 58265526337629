@use '../abstracts/' as *;

.Button {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 100%;
  &--disabled {
    box-shadow: none;
    cursor: not-allowed !important;
    filter: grayscale(70%) opacity(30%);
  }
  &--disabled:hover {
    box-shadow: none !important;
  }
  &--blue {
    background: $blue;
    border: 1px solid darken($blue, 20%);
    color: $white;

    & .button-text {
      color: $white;
    }
  }
  &--green {
    background: $green;
    border: 1px solid darken($green, 20%);
    color: $white;

    & .button-text {
      color: $white;
    }
  }
  &--red {
    background: $red;
    border: 1px solid darken($red, 20%);
    color: $white;

    & .button-text {
      color: $white;
    }
  }
  &--grey {
    background: $off-white;
    border: 1px solid darken($off-white, 20%);
    border: transparent;
    box-shadow: none;
    color: $charcoal;
    font-weight: 500;

    & .button-text {  
      color: $charcoal;
    }
  }
  &--loading {
    background-image: url('../../images/loading.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: progress;
  }
  &--loading-black {
    background-image: url('../../images/loading-black.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: progress;
  }
  &--small {
    height: 20px;
    line-height: 20px;
  }
  &--medium {
    width: 114px;
    height: 25px;
    font-size: 14px;
  }
  &--normal {
    height: 40px;
    line-height: 39px;
  }
  &--secondary {
    border: 1.5px solid;
    background: none;
    color: #373d48;
    &.Button--grey {
      border-color: darken($off-white, 20%);
      color: darken($off-white, 20%);

      & .button-text {
        color: darken($off-white, 20%);
      }
    }
    &.Button--red {
      border-color: darken($red, 20%);
      color: darken($red, 20%);

      & .button-text {
        color: darken($red, 20%);
      }
    }
    &.Button--green {
      border-color: darken($green, 20%);
      color: darken($green, 20%);

      & .button-text {
        color: darken($green, 20%);
      }
    }
    &.Button--blue {
      border-color: darken($blue, 20%);
      color: darken($blue, 20%);

      & .button-text {
        color: darken($blue, 20%);
      }
    }
  }
  &--no-border {
    border: none;
    box-shadow: none;
  }
}

.Button:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}
.Button:active {
  box-shadow: none;
}

.icon-left, .icon-right {
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-left{
  margin-right: 8px;
}

.icon-right {
  margin-left: 8px;
}

.button-text {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}
