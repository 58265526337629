@use '../abstracts/' as *;

.TopNagBar {
  width: 100%;
  height: 42px;
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  &--red {
    background-color: $red;
  }
  &--green {
    background-color: $green;
  }
  &--text {
    margin: 12px 0;
    text-align: right;
    font-weight: 700;
    color: $white;
  }
}
